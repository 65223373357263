<template>
  <div class="about">
    <section class="about__banner">
      <img src="https://assets-us-01.kc-usercontent.com:443/417b329d-e74d-002a-1bd9-45431bcbc680/7bd32e2f-ab1f-4780-ac54-f3b468ff41e5/about-img.png" alt="foto van Chantal Sloep" />
      <a href="http://www.frankdoorhof.com" target="_blank"><i class="fa fa-camera"></i> Frank Doorhof</a>

      <div class="container">
        <h3>&quot;Die Fotochick&quot;</h3>
			  <p class="--basecolor">Chantal Sloep, Muziekfotografe</p>	
      </div>
    </section>

    <section class="about__content container">
      <div class="about__content__left">
        <h1 class="heading__double">Over <span class="--basecolor">mij</span></h1>

        <template v-for="(item, index) in aboutItem" :key="index">
          <div v-html="item.content" class="about__content__main"></div>
        </template>

        <p>
			     <img src="@/assets/txt-img.png" width="130" /> <img src="@/assets/txt1-img.png" width="140" />
			  </p>

			  <ul class="about__socials">	
          <li class="about__socials__item">
            <SocialMediaLink channel="instagram" />
          </li>	

          <li class="about__socials__item">
            <SocialMediaLink channel="twitter" />
          </li>	

          <li class="about__socials__item">
            <SocialMediaLink channel="facebook" />
          </li>

          <li class="about__socials__item">
            <SocialMediaLink channel="linkedin" />
          </li>
			  </ul>
      </div>

      <div class="about__content__right">
        <h2 class="heading__double">Mijn<span class="--basecolor"> Ervaring</span></h2>

        <template v-for="(item, index) in experienceItem" :key="index">
          <div v-html="item.content" class="about__content__experience"></div>
        </template>
      </div>
    </section>
  </div>
</template>

<script>
import { computed, ref } from 'vue'
import { Client } from "../../client"

import SocialMediaLink from "@/components/SocialMediaLink"

import './about.scss'

export default {
  name: "About",
  setup() {
    const about = ref([])
    const experience = ref([])

    Client.items()
      .type('textblock')
      .equalsFilter('elements.name', 'about-me-text')
      .toObservable()
      .subscribe(response => about.value = response.items)

    const aboutItem = computed(() => about.value.map(item => ({ 
      content: item.content.value
    })))

    Client.items()
      .type('textblock')
      .equalsFilter('elements.name', 'my-experience-text')
      .toObservable()
      .subscribe(response => experience.value = response.items)

    const experienceItem = computed(() => experience.value.map(item => ({ 
      content: item.content.value
    })))

    return {
      aboutItem,
      experienceItem
    }
  },
  components: {
    SocialMediaLink
  }
}
</script> 