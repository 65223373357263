<template>
    <a class="about__socials__link" :href="selectedChannel.url">
        <i class="fa" :class="'fa-' + selectedChannel.class"></i>
        <span class="sr-only">{{ selectedChannel.name }}</span>
    </a>
</template>

<script>
import { computed } from 'vue'

export default {
  name: "Social media link",
  props:{
    channel: String
  },
  setup(props) {
    const socials = [
      {
        class: 'instagram',
        name: 'Instagram',
        url: 'https://www.instagram.com/diefotochick'
      },
      {
        class: 'twitter',
        name: 'Twitter',
        url: 'https://www.twitter.com/diefotochick'
      },
      {
        class: 'facebook',
        name: 'Facebook',
        url: 'https://www.facebook.com/diefotochick'
      },
      {
        class: 'linkedin',
        name: 'LinkedIn',
        url: 'https://www.linkedin.com/company/fotochick'
      }
    ];

    const selectedChannel = computed(() => socials.find((s) => s.class === props.channel))

    return { 
      selectedChannel
    }
  }
}
</script>